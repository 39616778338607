
import _this from "../../main";
import Api from '../../api/index.js';
//loading事件
import { Loading } from 'element-ui';
let loading;
export default {
    install(Vue) {
        Vue.prototype.loadconfig = function (isLoad,callback){
            var sysConfigStr = localStorage.getItem('sysConfig');
            if(isLoad){
                Api.changeColor().then(res=>{
                    if(res.data.status){
                        let getChangeColor = localStorage.getItem('changeColor')?localStorage.getItem('changeColor'):false;
                        if(getChangeColor != res.data.data){
                            Api.config().then(data=>{
                                if(data.data.status){
                                    this.$store.commit('setConfig', data.data.data);
                                    localStorage.setItem('sysConfig',JSON.stringify(data.data.data));
                                    localStorage.setItem('title',data.data.data.baseConfig.webTitle)
                                    localStorage.setItem('lunbo',data.data.data.baseConfig.imgWebUrl)
                                    localStorage.setItem('upload',data.data.data.baseConfig.sp_Type_DefPic)
                                    localStorage.setItem('base',JSON.stringify(data.data.data.baseConfig))
                                    localStorage.setItem('tmpTheme', JSON.stringify(data.data.data.colorConfig));
                                    this.$store.commit('updateTheme', data.data.data.colorConfig);
                                    callback&&callback(data.data.data);
                                } 
                            })
                        }
                        localStorage.setItem('changeColor',res.data.data)
                    } 
                })
            } 
            
            if(sysConfigStr){
                let sysConfig = JSON.parse(sysConfigStr)
                this.$store.commit('setConfig', sysConfig);
                this.$store.commit('updateTheme', sysConfig.colorConfig);
                localStorage.setItem('tmpTheme', JSON.stringify(sysConfig.colorConfig));
                callback&&callback(sysConfig);
            }else{
                Api.config().then(res=>{
                    if(res.data.status){
                        this.$store.commit('setConfig', res.data.data);
                        localStorage.setItem('sysConfig',JSON.stringify(res.data.data));
                        localStorage.setItem('title',res.data.data.baseConfig.webTitle)
                        localStorage.setItem('lunbo',res.data.data.baseConfig.imgWebUrl)
                        localStorage.setItem('upload',res.data.data.baseConfig.sp_Type_DefPic)
                        localStorage.setItem('base',JSON.stringify(res.data.data.baseConfig))
                        localStorage.setItem('tmpTheme', JSON.stringify(res.data.data.colorConfig));
                        this.$store.commit('updateTheme', res.data.data.colorConfig);
                        callback&&callback(res.data.data);
                    }
                })
            }
        },
        // 开启loading事件
        Vue.prototype.startLoading = function (){
            loading = Loading.service({
                lock: true,
                text: '请稍后……',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        // 关闭loading事件
        Vue.prototype.endLoading = function (){
            loading.close()
        },
		//精确计算乘法
        Vue.prototype.accMul=function(arg1,arg2){ 
        
            var m = 0;
            m += this.deal(arg1);
            m += this.deal(arg2);
            var r1 = Number(arg1.toString().replace(".", ""));
            var r2 = Number(arg2.toString().replace(".", ""));
            return(r1 * r2) / Math.pow(10, m)
        
        },
		//精确计算加法
        Vue.prototype.accAdd=function(arg1, arg2){ 
        
            var r1 = this.deal(arg1);
			var r2 = this.deal(arg2);
			var m = Math.pow(10, Math.max(r1, r2))
			return(arg1 * m + arg2 * m) / m
        
        },
		//精确计算减法
        Vue.prototype.accSub=function(arg1, arg2){ 
        
            var r1 = this.deal(arg1);
			var r2 = this.deal(arg2);
			var m = Math.pow(10, Math.max(r1, r2))
			return(arg1 * m - arg2 * m) / m
        
        },
		// 求小数点后的数据长度
        Vue.prototype.deal=function(arg){ 
        
            var t = 0;
            try {
                t = arg.toString().split(".")[1].length
            } catch(e) {}
            return t;
        
        }
    }
}