
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  //重定向操作
  {path:'/home',redirect:'/'},
  {path:'/',component: () => import('../views/Home.vue')},
  // {
  //   path: '/index',
  //   component: () => import('../views/Index.vue'),
  //   children:[
      // {path:'/',redirect:'/home'},
      {path:'/category',component: () => import('../views/Category.vue')},
      {path:'/categorySearch',component: () => import('../views/CategorySearch.vue')},
      {path:'/terminalMode',component: () => import('../views/terminalMode.vue')},
      {path:'/distribution',component: () => import('../views/Distribution.vue')},
      {path:'/supply',component: () => import('../views/Supply.vue')},
      {path:'/catlist',component: () => import('../views/Catlist.vue')},
      {path:'/productview',component: () => import('../views/productview.vue')},
      {path:'/largeViewImge',component: () => import('../views/largeViewImge.vue')},
      {path:'/news',component: () => import('../views/News.vue'),
        children:[
          // {path:'/',redirect:'/show'},
          // {path:'/show',component: () => import('../components/Show.vue')}
        ]
      },
      {path:'/cart',component: () => import('../views/Cart.vue')},
      {path:'/settle',component: () => import('../views/Settlement.vue')},
      {path:'/paytype',component: () => import('../views/Paytype.vue')},
      {path:'/PaytypeForAcc',component: () => import('../views/PaytypeForAcc.vue')},
      {path:'/PaytypeForOrder',component: () => import('../views/PaytypeForOrder.vue')},
      {path:'/completion',component: () => import('../views/Completion.vue')},
      {path:'/CompletionAccount',component: () => import('../views/CompletionAccount.vue')},
      {path:'/CompletionForYuFu',component: () => import('../views/CompletionForYuFu.vue')},
      {path:'/WechatPay',component: () => import('../views/WechatPay.vue')},
      {
        path:'/yuFuPay',
        component: () => import('../views/yuFuPay.vue')
      },
      {path:'/quickOrder',component: () => import('../views/QuickOrder.vue')},
      {
        path:'/Mine',
        component: () => import('../views/Mine.vue'),
        children:[
          {path:'/',redirect:'/membercenter'},
          {path:'/membercenter',component: () => import('../views/mine/MemberCenter.vue')},
          {path:'/order',component: () => import('../views/mine/Order.vue')},
          {path:'/notice',component: () => import('../views/mine/Notice.vue')},
          {path:'/collect',component: () => import('../views/mine/Collect.vue')},
          {path:'/transaction',component: () => import('../views/mine/Transaction.vue')},
          {path:'/transactiondetail',component: () => import('../views/mine/TransactionDetail.vue')},
          {path:'/procure',component: () => import('../views/mine/Procure.vue')},
          {path:'/record',component: () => import('../views/mine/Record.vue')},
          {path:'/invoicingmanage',component: () => import('../views/mine/Invoicingmanage.vue')},
          {path:'/invoicing',component: () => import('../views/mine/Invoicing.vue')},
          {path:'/station',component: () => import('../views/mine/Station.vue')},
          {path:'/personal',component: () => import('../views/mine/Personal.vue')},
          {path:'/modify',component: () => import('../views/mine/Modify.vue')},
          {path:'/address',component: () => import('../views/mine/Address.vue')},
          {path:'/children',component: () => import('../views/mine/Children.vue')},
          {path:'/relation',component: () => import('../views/mine/Relation.vue')},
          {path:'/orderdetail',component: () => import('../views/mine/OrderDetail.vue')},
          {path:'/returndetail',component: () => import('../views/mine/ReturnDetail.vue')},
          {path:'/apply',component: () => import('../views/mine/Apply.vue')},
          {path:'/account',component: () => import('../views/mine/AccountList.vue')},
          {path:'/advance',component: () => import('../views/mine/Advance.vue')},
          {path:'/accountdetail',component: () => import('../views/mine/AccountDetail.vue')},
          {path:'/promotion',component: () => import('../views/mine/Promotion.vue')},
          {path:'/promotion_tj',component: () => import('../views/mine/Promotion_tj.vue')},
          {path:'/commission',component: () => import('../views/mine/Commission.vue')},
          {path:'/cash',component: () => import('../views/mine/Cash.vue')},
          {path:'/cashlist',component: () => import('../views/mine/Cashlist.vue')},
          {path:'/Complaint',component: () => import('../views/mine/Complaint.vue')},
          {path:'/ComplaintDetail',component: () => import('../views/mine/ComplaintDetail.vue')},
        ]
      },
  //   ]
  // },
  
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/modify',
    component: () => import('../components/logins/modify.vue')
  },
  {
    path: '/register',
    component: () => import('../components/logins/register.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
