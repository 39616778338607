
<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  computed:{
      tmpTheme(){
          return {
              '--bgColor':this.$store.state.sysConfig.colorConfig.bgColor,
              '--fontColor':this.$store.state.sysConfig.colorConfig.fontColor
          };
      }
  },
  created(){
      const _this = this;
      _this.loadconfig(false,function(data){})
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function() {
         this.isRouterAlive = true
      })
    },
    handleBeforeUnload(event) {
      localStorage.removeItem('areaData')
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
}
</script>

<style lang="scss">
@import url('./assets/font/iconfont.css');
*{
  margin: 0;
  padding: 0;
}
body {
    background-color: #f7f7f7;
    font-size: 14px;
}
li{
  list-style-type: none;
}
a{
  text-decoration: none;
}
.nothing {
  padding: 50px 0;
  text-align: center;
  color: #999;
}
.search_nothing {
  text-align: center;
  padding: 50px 0;
  width: 320px;
  margin: 0 auto;
}
.search_nothing .sorry {
  text-align: left
}
.search_nothing .sorry_info {
  text-align: left;
  color: #666;
  margin-top: 10px;
  margin-bottom: 50px;
}
.search_nothing .sorry_info a{
    color: #a41111;
}
.search_nothing .btn {
  padding: 10px 20px;
  background-color: #a41111;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
input[type=checkbox] {
    cursor: pointer;
    position: relative;
    width: 15px;
    height: 15px;
    font-size: 12px;
    margin-right: 2px;
    vertical-align: -2px;
}
input[type=checkbox]:checked:after {
    content: "✓";
    color: #fff;
    font-size: 12px;
    background-color: var(--bgColor,#e60012);
    line-height: 15px;
}
input[type=checkbox]:after {
    position: absolute;
    top: 0;
    width: 15px;
    height: 15px;
    display: inline-block;
    visibility: visible;
    padding-left: 0;
    text-align: center;
    border-radius: 3px;
}
.page {
    text-align: center;
    padding: 30px 0;
}
</style>
<style>
.el-dialog__header {
    /* padding: 10px 20px; */
    /* background-image: linear-gradient(to right, #fba980,#f7ca6d)!important; */
}
.el-dialog__title {
    /* color: #fff; */
}
.el-dialog__headerbtn {
    /* top: 12px; */
}
.el-dialog__headerbtn .el-dialog__close {
    /* color: #fff; */
}
.el-dialog {
    border-radius: 10px;
    overflow: hidden;
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: var(--bgColor,#e60012)!important;
  border-color:var(--bgColor,#e60012)!important;
}
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  box-shadow: -1px 0 0 0 #f7ca6d!important
}
/* .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #a41111!important;
} */
.el-table thead {
    color: #333!important;
    font-weight: unset;
}
.van-loading {
  padding: 30px 0;
}
.img {
  background: url(./assets/img/u_avatar.png);
}
.el-message {
  top: 50% !important;
  margin-top: -100px !important;
  z-index: 100000002 !important;
}
</style>